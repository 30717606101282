
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../../../app/service/user.service';
import {User} from '../../../../app/model/user';
import { ValidationSchema } from '../../../file-validation-maintenance/model/validation-schema';
import { FormBuilderService } from '../../services/form-builder.service';

@Component({
  selector: 'app-field-selection',
  templateUrl: './field-selection.component.html',
  styleUrls: ['./field-selection.component.css']
})
export class FieldSelectionComponent implements OnInit {
  title: string;
  form: FormGroup;
  enteredFieldName: string = '';
  user: User;
  validationSchema: ValidationSchema;
  public event: EventEmitter<any> = new EventEmitter();
  fieldNames = [];
  selectedName: any;
  saveFlag: boolean = true;
  nameToSearch: string;
  myContent: any;
  formData: any;
  selectedDataCallFields: any = [];

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private formBuilderService: FormBuilderService
  ) { }

  ngOnInit() {
   this.formData = this.formBuilderService.getFilingBlank();
   this.selectedDataCallFields =  this.formBuilderService.getSelectedDataCallFieldsList();
    this.title = `Select Field from Datacall`;
    this.user = this.userService.getStoredUser();
   this. disableFieldsIfFieldNameIsSelected();
  }

  disableFieldsIfFieldNameIsSelected(){
    this.fieldNames = this.formBuilderService.getFieldName();
   this.fieldNames.forEach(element => {
      if(this.selectedDataCallFields.includes(element.fieldNames) || element.isAssignmentsKey === true){
        element.isDisable = true;
      } else {
        element.isDisable = false;
      }
    });
    this.fieldNames = this.fieldNames.sort((a, b) => {
      if (a.isDisable !== b.isDisable) {
        return a.isDisable ? 1 : -1;
      }
      return a.orderNumber - b.orderNumber;
    });
  }

  search(searchText) {
    if (searchText === undefined || searchText === '') {
      this.fieldNames = this.formBuilderService.getFieldName();
    } else {
      this.fieldNames = this.formBuilderService.getFieldName();
      const result = this.fieldNames.filter(filteredText => (filteredText.fieldNames).toLocaleLowerCase().startsWith(String(searchText).toLocaleLowerCase()));
      this.fieldNames = result;
    }
  }

  selectField(field) {
    this.enteredFieldName = `${field}`;
    this.saveFlag = false;
  }

  ok() {
    this.triggerEvent(this.enteredFieldName);
     this.formBuilderService.recursiveGetKeys(this.formData.sections);
    this.bsModalRef.hide();
  }

  triggerEvent(value: string) {
    this.event.emit({ data: value, res: 200 })
  }
}
