<div class="tab-content active">

    <div [formGroup]="sectionForm">
        <div [formGroupName]="section.key" class="well well-lg" [id]="section.key">
            <div class="row pb-3" *ngIf="isSectionVisible">
                    <div>
                        <h5 class="fontBold">
                            <span [ngClass]="getSectionStyles()" (click)="toggleCollapse($event)"
                                [attr.aria-expanded]="!isActionsCollapsed" aria-controls="actions" style="cursor: pointer; display: flex; align-items: center;">
                                <fa-icon [icon]="isCollapsed ? faCaretRight : faCaretDown" class="pr-2"></fa-icon>
                                <div *ngIf="dependenciesService.selectedSectionKey !== section.key">
                                    <input type="checkbox" [checked]="isSectionOrQuestionOrInputChecked(section)" (click)="$event.stopPropagation(); checkSection($event, section)" [disabled]="isSectionCheckBoxDisabledForDependency(section)" style="margin-right: 5px;"> <!-- Stop event propagation -->
                                </div>
                                {{section.name}}
                            </span>
                        </h5>
                    </div>
            </div>
            <div [collapse]="isCollapsed" *ngIf="isSectionVisible">
                <ng-container>
                    <div class="row" *ngIf="section.questions.length > 0">
                        <div class="col-4"></div>
                        <ng-container *ngIf="inputLabels.length > 0">
                            <div *ngFor="let label of inputLabels" class="col table-container">
                                <div class="row align-items-center justify-content-center">
                                    <div class="pr-2" style="display: flex; align-items: center;" *ngIf="dependenciesService.selectedSectionKey !== section.key">
                                        <input type="checkbox" [checked]="isSectionOrQuestionOrInputChecked(label)" (click)="changeInput($event, label, section, inputLabels)" [disabled]="isCheckBoxDisabledForDependency(section)">
                                    </div>
                                    <h5 class="text-center mb-0">{{label.label}}</h5>
                                </div>
                            </div>
                        </ng-container>
                    </div>                                    
                </ng-container>
                <div *ngFor="let model of updatedModel; let i = index;">
                    <div class="row pl-3">
                        <!-- Heading -->
                        <ng-container>
                            <div class="col" *ngIf="model.label === '' else question">
                                <h5 style="width: 70rem; white-space: normal; word-wrap: break-word;">{{model.heading}}</h5>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #question>
                        <!-- Questions -->
                        <div [formGroupName]="model.label" class="col well well-lg indent-questions pl-5" [id]="model.label">
                            <div [ngClass]="getBackgroundQuestion(model.text, model.key)" class="form-group row">
                                <div *ngIf=" model.key !== dependenciesService.selectedQuestionKey">
                                    <input type="checkbox" [checked]="isSectionOrQuestionOrInputChecked(model)" (click)="changeQuestion($event, model, updatedModel, section)" [disabled]="isCheckBoxDisabledForDependency(section)">
                                </div>
                                <div class="col-4" style="white-space: normal; word-wrap: break-word;">
                                    <b>{{getQuestionNumber(model.label)}}</b>&nbsp;&nbsp;&nbsp;&nbsp;{{model.text}}
                                </div>
                                <!-- Inputs -->

                                <ng-container *ngIf="getOptionsVisible(model.text, model.key)">
                                    <ng-container *ngFor="let input of model.inputs">
                                        <ng-container [ngSwitch]="input.controlType">
                                            <div class="col text-center">
                                                <!-- Switch Case: Dropdown -->
                                                <ng-container *ngSwitchCase="'dropdown'">
                                                    <select [id]="input.schemaPropertyNameKey" [name]="input.schemaPropertyNameKey"
                                                        [formControlName]="input.schemaPropertyNameKey" [ngClass]="getInputStyle(input)"
                                                        [attr.title]="input.label"
                                                        (change)="changeSelectedQuestionValue($event, input)">
                                                        <option value="" disabled hidden>Choose One</option>
                                                        <option *ngFor="let opt of input.options">
                                                            {{opt.value}}</option>
                                                    </select>
                                                </ng-container>

                                                <ng-container *ngSwitchDefault> - </ng-container>

                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <button *ngIf="getOptionsVisible(model.text, model.key)" id="delete" type="button" class="btn btn-danger" (click)="deleteRule(model)" [disabled]="isDeleteDisabled()">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>

                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isSectionVisible">
        <hr/>
    </div>
</div>
<!-- More Sections -->
<ng-container *ngIf="summary">
    <app-set-dependencies-establish-rule *ngFor="let child of section.sections; let i = index" [section]="child"
        [parentId]="getSectionId()" [disabled]="true" [parentPath]="path" [summary]="true"
        [questionNo]="getQuestionNumbers()"
        [filteredQuestions]="filteredQuestions"
        [isSectionVisible] = "isSectionVisible"
        [count]="false"></app-set-dependencies-establish-rule>
</ng-container>