import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../model/user';
import { UserService } from '../service/user.service';
import { MessageService } from '../messages/service/message.service';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FormBuilderService } from './services/form-builder.service';
import { FilingBlank } from './model/filingblank';
import { FileValidationMaintenanceService } from '../file-validation-maintenance/services/file-validation-maintenance.service';
import * as _ from 'lodash';
import { ValidationSchema } from '../file-validation-maintenance/model/validation-schema';
import { disableFieldName } from './model/form-fieldName-disable';
import { DependenciesService } from './dependencies/services/dependencies.service';
import { AlertMessage } from '../messages/model/alert-message';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.css']
})
export class FormBuilderComponent implements OnInit, AfterViewChecked, OnDestroy {
  user: User;
  schema: string;
  title: string;
  isSidebarCollapsed = true;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  maxSideBarWidth = '450px';
  minSideBarWidth = '0px';
  showErrorMessages: boolean = false;
  filingBlank: FilingBlank;
  selectedFormSection: any;
  validationSchema: ValidationSchema;
  fieldNames: any[];
  checkChanges: boolean = false;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    public formBuilderService: FormBuilderService,
    private schemaMaintenanceService: FileValidationMaintenanceService,
    private cdRef : ChangeDetectorRef,
    private dependencyService: DependenciesService
  ) { }

  ngAfterViewChecked(): void {
    if(this.checkChanges !== this.formBuilderService.showDependencyPage.value) {
      this.checkChanges = this.formBuilderService.showDependencyPage.value;
      this.cdRef.detectChanges();
    }
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    this.title = `${this.schema} Form Builder`;
    this.filingBlank = new FilingBlank([],[]);
    this.getFilingBlankLayout();
    this.formBuilderService.setClickedName('');
    this.dependencyService.isDependencyQuestionClicked.subscribe((value: boolean) => {
      this.isSidebarCollapsed = !value;
      this.toggleSideBar();
    });
  }

  ngOnDestroy() {
    this.dependencyService.isDependencyQuestionClicked.next(false);
  }

  toggleSideBar() {
    if (this.isSidebarCollapsed) {
      document.getElementById('sideNav').style.width = this.maxSideBarWidth;
      document.getElementById('main').style.marginLeft = this.maxSideBarWidth;
      document.getElementById('toggleBtn').style.marginLeft = this.maxSideBarWidth;
    } else {
      document.getElementById('sideNav').style.width = this.minSideBarWidth;
      document.getElementById('main').style.marginLeft = this.minSideBarWidth;
      document.getElementById('toggleBtn').style.marginLeft = this.minSideBarWidth;
    }
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    document.getElementById('toggleBtn').blur();
  }

  getFilingBlankLayout() {
    this.formBuilderService.loading.next(true);
    this.formBuilderService.getFilingBlankLayout(this.user.currentSchema).subscribe(
      (res) => {
        if (res.status === 200) {
          this.filingBlank = res.body;
          (this.filingBlank.sections.length !== 0) ? this.formBuilderService.sideNavTitle.next('Page Navigation') : 'Set Page Navigation';
          this.formBuilderService.setFilingBlank(this.filingBlank);
          this.formBuilderService.setOriginalFilingBlank(this.filingBlank);
        }
        this.getCurrentValidationSchemaByType();
      }, (err) => {
        let filingBlank = new FilingBlank([],[])
        this.formBuilderService.sideNavTitle.next('Set Page Navigation');
        this.formBuilderService.setFilingBlank(filingBlank);
        this.formBuilderService.setOriginalFilingBlank(filingBlank);
        this.formBuilderService.isFilingBlankEmpty.next(true);
        let message = 'No Values to Return for Form: OnlineDataEntryService: getFilingBlankLayout: 404';
        this.messageService.add(new AlertMessage(message, 'danger'));
        this.getCurrentValidationSchemaByType();
      }
    )
  }

  getCurrentValidationSchemaByType(): void {
    this.schemaMaintenanceService.getCurrentValidationSchemaByType(this.schema)
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;
          this.fieldNames = this.getFieldNames();
          this.formBuilderService.setFieldName(this.fieldNames);
          this.formBuilderService.loading.next(false);
        }
      );
  }

  getFieldNames(): disableFieldName[] {
    const fields: Array<any> = [];
    const keys: Array<string> = this.getPropertiesKeys();
    keys.forEach(key => {
      const data = {};
      data['fieldNames'] = key;
      data['isDisable'] = false;
      data['isAssignmentsKey'] = this.validationSchema.properties[key].isAssignmentsKey;
      data['isEnumValue'] = (this.validationSchema.properties[key].enum) ? true : false;
      data['orderNumber'] = this.validationSchema.properties[key].orderNumber;
      fields.push(data);
    });
    return fields;
  }

  getPropertiesKeys(): string[] {
    return _.keys(this.validationSchema.properties);
  }

  selectedFormSectionHandler(event) {
    this.selectedFormSection = event;
  }

}
